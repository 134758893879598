import { createStore } from 'vuex'

import auth from './modules/auth'
import interceptors from './modules/interceptors'
import membership from './modules/membership'
import academy from './modules/academy'
import section from './modules/section'
import lesson from './modules/lesson'
import wallet from './modules/wallet'
import signal from './modules/signal'
import tradingLive from './modules/tradingLive'
import chat from './modules/chat'
import stage from './modules/stage'
import cart from './modules/cart'
import tradingPlan from './modules/tradingPlan'
import user from './modules/user'
import staking from './modules/staking'
import withdraw from './modules/withdraw'
import plan from './modules/plan'
import util from './modules/util'
import security from './modules/security'

import vuexPersistence from '../plugins/vue-persistence'

export default createStore({
  state: {
    appName: 'B7',
    progressBarState: null
  },
  modules: {
    auth,
    interceptors,
    membership,
    academy,
    section,
    lesson,
    wallet,
    signal,
    tradingLive,
    chat,
    stage,
    cart,
    tradingPlan,
    user,
    staking,
    withdraw,
    plan,
    util,
    security
  },
  plugins: [vuexPersistence.plugin]
})
