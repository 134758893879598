import axios from 'axios'

export default {
  namespaced: true,
  state: {
    plans: []
  },
  getters: {},
  mutations: {
    GET_PLANS (state, data) {
      state.plans = data
    }
  },
  actions: {
    async getPlans (context) {
        const response = await axios.get('/api/v1/plan')
        context.commit('GET_PLANS', response.data)
        return response.data.meta
    }
  }
}
