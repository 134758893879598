import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "App",
    component: () => import("@/layouts/App.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/views/dashboard/Index2"),
      },
      {
        path: "membership",
        name: "Membership",
        component: () => import("@/views/membership/Index"),
      },
      {
        path: "purchase",
        name: "Purchase",
        component: () => import("@/views/cart/BuyMembership"),
      },
      {
        path: "/purchase/:type/membership/:id",
        name: "Purchase-Membership",
        component: () => import("@/views/purchase/Membership"),
        props: true
      },
      {
        path: "purchase/:type/method/:id/:extra?",
        name: "Purchase-Method",
        component: () => import("@/views/purchase/Method"),
        props: true
      },
      {
        path: "purchase/:id/payment",
        name: "Purchase-Payment",
        component: () => import("@/views/cart/Index"),
        props: true,
      },
      /* {
        path: "membership/:price/:id/purchase",
        name: "Purchase-Membership",
        component: () => import("@/views/membership/Purchase"),
        props: true,
      }, */
      {
        path: "membership/details",
        name: "Membership-Details",
        component: () => import("@/views/membership/Details"),
      },
      {
        path: "staking",
        name: "Staking",
        component: () => import("@/views/staking/Index"),
      },
      {
        path: "staking/create",
        name: "Create-Staking",
        component: () => import("@/views/staking/create/Index"),
      },
      {
        path: "academy",
        name: "Academy",
        component: () => import("@/views/academy/Index"),
      },
      {
        path: "academy/:id/view/:name",
        name: "View-Course",
        component: () => import("@/views/academy/view/Index"),
        props: true,
      },
      {
        path: "academy/:id/take/:name",
        name: "Take-Course",
        component: () => import("@/views/academy/take/Index"),
        props: true,
      },
      {
        path: "trading-live",
        name: "Trading-Live",
        component: () => import("@/views/tradingLive/Index"),
      },
      {
        path: "trading-live/meet/:id/:name",
        name: "Meet-Live",
        component: () => import("@/views/tradingLive/LiveClass"),
        props: true,
      },
      {
        path: "trading-plans",
        name: "Trading-Plans",
        component: () => import("@/views/tradingPlans/Index"),
      },
      {
        path: "signals",
        name: "Signal",
        component: () => import("@/views/signal/Index"),
      },
      {
        path: "team/:username",
        name: "Team",
        component: () => import("@/views/team/Index"),
        props: true,
      },
      {
        path: "wallet",
        name: "Wallet",
        component: () => import("@/views/wallet/Index2"),
      },
      {
        path: "transactions",
        name: "Transactions",
        component: () => import("@/views/transactions/Index"),
      },
      {
        path: "chat",
        name: "Chat",
        component: () => import("@/views/chats/Index"),
      },
      {
        path: "tokens",
        name: "Tokens",
        component: () => import("@/views/tokens/Index"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("@/views/profile/Index"),
      },
      {
        path: "payment-initial",
        name: "Payment-Initial",
        component: () => import("@/views/force/Index"),
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("@/layouts/Auth.vue"),
    children: [
      {
        path: "signin",
        name: "Signin",
        component: () => import("@/views/auth/Signin"),
      },
      {
        path: "signup/:username?",
        name: "Signup",
        component: () => import("@/views/auth/Signup"),
        props: true,
      },
      {
        path: "recover-password",
        name: "RecoverPassword",
        component: () => import("@/views/auth/RecoverPassword"),
      },
      {
        path: "recover/:id/:hash",
        name: "RestorePassword",
        component: () => import("@/views/auth/RestorePassword"),
        props: true,
      },
      {
        path: "validation",
        name: "Validation",
        component: () => import("@/views/auth/Validation"),
      },
    ],
  },
  {
    path: "/manage",
    name: "Manage",
    component: () => import("@/layouts/App.vue"),
    children: [
      {
        path: "",
        name: "Manage-Dashboard",
        component: () => import("@/views/manage/dashboard/Index"),
      },
      {
        path: "membership",
        name: "Manage-Membership",
        component: () => import("@/views/manage/membership/Index"),
      },
      {
        path: "academy",
        name: "Manage-Academy",
        component: () => import("@/views/manage/academy/Index"),
      },
      {
        path: "academy/sections/:id/:name",
        name: "Manage-Course",
        component: () => import("@/views/manage/academy/manage/Index"),
        props: true,
      },
      {
        path: "trading-live",
        name: "Manage-Trading-Live",
        component: () => import("@/views/manage/tradingLive/Index"),
      },
      {
        path: "signals",
        name: "Manage-Signal",
        component: () => import("@/views/manage/signal/Index"),
      },
      {
        path: "transactions",
        name: "Manage-Transactions",
        component: () => import("@/views/transactions/Index"),
      },
      {
        path: "tokens",
        name: "Manage-Tokens",
        component: () => import("@/views/manage/tokens/Index"),
      },
      {
        path: "users",
        name: "Manage-Users",
        component: () => import("@/views/manage/users/Index"),
      },
      {
        path: "purchases",
        name: "Manage-Purchases",
        component: () => import("@/views/manage/purchases/Index"),
      },
      {
        path: "kyc",
        name: "Manage-Kyc",
        component: () => import("@/views/manage/kyc/Index"),
      },
      {
        path: "withdraw",
        name: "Manage-Withdrawals",
        component: () => import("@/views/manage/withdraw/Index"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.auth.user;
  /* to.name !== 'login' &&  */
  const publicPages = [
    "Signin",
    "RecoverPassword",
    "Signup",
    "RestorePassword",
  ];
  const authRequired = !publicPages.includes(to.name);
  if (authRequired && loggedIn === null) {
    next({
      path: "/auth/signin",
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
