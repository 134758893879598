import axios from 'axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {
    async getCountries (context, id) {
      const response = await axios.get(`/api/v1/utils/countries`)
      return response.data
    }
  }
}
