<template>
  <div class="copy" @click="copyURL(address)">
    <p class="copy__text">{{ address }}</p>

    <div class="img">
      <svg
        width="16"
        height="16"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="7.49805"
          y="6.99805"
          width="14.0058"
          height="14.0058"
          rx="2"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.49776 17.0022H5.49693C4.3919 17.0022 3.49609 16.1064 3.49609 15.0013V4.99717C3.49609 3.89214 4.3919 2.99634 5.49693 2.99634H15.5011C16.6061 2.99634 17.5019 3.89214 17.5019 4.99717V6.998"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    address: {
      required: true,
    },
    color: {
      default: 'normal',
    },
  },
  methods: {
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        openNotification('Successfully copied');
      } catch ($e) {
        openNotification('Please, try later', 'error');
      }
    },
  },
};
</script>
<style>
.copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  color: #fff;
  background: #47aae00e;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.img {
  margin-left: 10px;
}
.copy__text {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  margin: 0;
}
@media (max-width: 600px) {
  .copy__text {
    max-width: 150px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media (min-width: 400px) {
  .copy__text {
    max-width: 200px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
